// @ts-nocheck
/* eslint-disable react/button-has-type */
import React, { cloneElement, forwardRef } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import type { Element } from "react";
import Typography from "../Typography";
import LoadingWheel from "../../svg/loading-wheel.svg";
import "./button.scss";

type IconProps = {
  icon?: Element<any> | null;
  loading?: boolean;
  modifier?:
    | "primary"
    | "secondary"
    | "transparent"
    | "danger"
    | "white"
    | "white-black"
    | "brand-tertiary"
    | "brand-danger"
    | "primary-grey-disabled"
    | "grey"
    | "secondary-grey";
  outline?: boolean;
};
type Props = IconProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    onClick?: (event: SyntheticEvent<HTMLButtonElement>) => any;
    onKeyDown?: () => any;
    children: React.ReactNode;
    type?: "button" | "submit" | "reset";
    className?: string;
    pill?: boolean;
    text: boolean;
    disabled?: boolean;
    cypress?: string | null;
    tabIndex?: number | null;
    size?: "primary" | "h3" | "subtext" | "secondary" | "h2";
    bold?: boolean;
    narrow?: boolean;
    lineHeight?: "small" | "large";
    hideTextDuringLoading?: boolean;
  };
const Icon = ({ icon, loading, modifier, outline }: IconProps) => {
  const className = bem("c-button", "icon", {
    loading,
    modifier,
    outline,
    icon: icon !== null
  });
  if (!loading && icon) {
    return cloneElement(icon, {
      className: `${className} ${icon.props.className || ""} `,
      role: "presentation"
    });
  }
  if (loading) {
    return <LoadingWheel className={className} role="presentation" />;
  }
  return null;
};

/**
 * Primary call to action
 */
const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      onClick = () => {},
      onKeyDown = () => {},
      type = "button",
      modifier = "primary",
      icon = null,
      className = "",
      outline = false,
      text,
      pill = false,
      disabled = false,
      loading = false,
      tabIndex = null,
      cypress = null,
      size = "h3",
      bold = true,
      narrow = false,
      lineHeight = null,
      hideTextDuringLoading = false
    }: Props & IconProps,
    ref
  ) => {
    const classNames = bem("c-button", null, {
      modifier,
      pill,
      outline,
      text,
      loading,
      icon: icon !== null || loading,
      "icon-only": icon && !children,
      narrow,
      lineHeight,
      "hide-text": loading && hideTextDuringLoading
    });
    const showIcon = icon !== null || loading;
    return (
      <button
        className={`${classNames} ${className}`}
        type={type}
        onClick={loading ? null : onClick}
        onKeyDown={onKeyDown}
        disabled={disabled}
        ref={ref}
        tabIndex={tabIndex}
        data-cy={cypress}
      >
        {showIcon && (
          <Icon
            loading={loading}
            icon={icon}
            modifier={modifier}
            outline={outline}
          />
        )}

        <Typography
          size={size}
          bold={bold}
          className={bem("c-button", "text", {
            icon: showIcon
          })}
        >
          {children}
        </Typography>
      </button>
    );
  }
);

export default Button;
