import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@not-remix-run/react';
import Tabs, { TabItem } from '@justpark/ui/src/components/Tabs';
import BrandTypography from '@justpark/ui/src/components/BrandTypography';

import styles from './topLocations.module.scss';
import { ampli } from '../../helpers/Tracking/amplitude';

const topAttractions = [
  ['Buckingham Palace', '/uk/parking/buckingham-palace/'],
  ['London Eye', '/uk/parking/london-eye/'],
  ['Windsor Castle', '/uk/parking/windsor-castle-royal-palace/'],
  ['Natural History Museum', '/uk/parking/natural-history-museum/'],
  ['Tower of London', '/uk/parking/tower-hill/'],
  ['Kew Gardens', '/uk/parking/kew-gardens/'],
  ['Edinburgh Castle', '/uk/parking/edinburgh-castle/'],
  ['Westfield Shopping Centre', '/uk/parking/westfield/'],
  ['Brighton Pier', '/uk/parking/brighton-palace-pier/'],
  ['O2 Arena', '/uk/parking/o2-arena/'],
  ['Hyde Park', '/uk/parking/hyde-park/'],
  ["St. Paul's Cathedral", '/uk/parking/st-pauls-cathedral/'],
  ['The Roman Baths', '/uk/parking/bath/'],
  ['Camden Market', '/uk/parking/camden-town/'],
  ['Westminster Abbey', '/uk/parking/westminster-abbey/'],
  ['London Zoo', '/uk/parking/london-zoo/'],
  ['York Minster', '/uk/parking/york/minster/'],
  ['Wembley Stadium', '/uk/parking/wembley-stadium/']
];

const topCities = [
  ['London', 'https://www.justpark.com/uk/parking/london/'],
  ['Manchester', 'https://www.justpark.com/uk/parking/manchester/'],
  ['Birmingham', 'https://www.justpark.com/uk/parking/birmingham/'],
  ['Bristol', 'https://www.justpark.com/uk/parking/bristol/'],
  ['Brighton', 'https://www.justpark.com/uk/parking/brighton/'],
  ['Liverpool', 'https://www.justpark.com/uk/parking/liverpool/'],
  ['Edinburgh', 'https://www.justpark.com/uk/parking/edinburgh/'],
  ['Oxford', 'https://www.justpark.com/uk/parking/oxford/'],
  ['York', 'https://www.justpark.com/uk/parking/york/'],
  ['Leeds', 'https://www.justpark.com/uk/parking/leeds/'],
  ['Glasgow', 'https://www.justpark.com/uk/parking/glasgow/'],
  ['Cardiff', 'https://www.justpark.com/uk/parking/cardiff/'],
  ['Bath', 'https://www.justpark.com/uk/parking/bath/'],
  ['Sheffield', 'https://www.justpark.com/uk/parking/sheffield/'],
  ['Cambridge', 'https://www.justpark.com/uk/parking/cambridge/'],
  ['Nottingham', 'https://www.justpark.com/uk/parking/nottingham/'],
  ['Belfast', 'https://www.justpark.com/us/parking/me/belfast/'],
  ['Norwich', 'https://www.justpark.com/uk/parking/norwich/']
];

const topAirports = [
  ['Heathrow Airport', 'https://www.justpark.com/uk/parking/heathrow-airport/'],
  ['Gatwick Airport', 'https://www.justpark.com/uk/parking/gatwick-airport/'],
  ['Stansted Airport', 'https://www.justpark.com/uk/parking/stansted-airport/'],
  [
    'London Luton Airport',
    'https://www.justpark.com/uk/parking/luton-airport/'
  ],
  [
    'London City Airport',
    'https://www.justpark.com/uk/parking/london-city-airport/'
  ],
  [
    'Birmingham Airport',
    'https://www.justpark.com/uk/parking/birmingham-airport/'
  ],
  [
    'Manchester Airport',
    'https://www.justpark.com/uk/parking/manchester-airport/'
  ],
  [
    'Newcastle Airport',
    'https://www.justpark.com/uk/parking/newcastle-airport/'
  ],
  [
    'Bournemouth Airport',
    'https://www.justpark.com/uk/parking/bournemouth-airport/'
  ],
  [
    'Bristol International Airport',
    'https://www.justpark.com/uk/parking/bristol-international-airport/'
  ],
  [
    'East Midlands International Airport',
    'https://www.justpark.com/uk/parking/east-midlands-international-airport/'
  ],
  [
    'Liverpool John Lennon Airport',
    'https://www.justpark.com/uk/parking/liverpool/liverpool-john-lennon-airport/'
  ],
  [
    'Leeds Bradford International Airport',
    'https://www.justpark.com/uk/parking/leeds/leeds-bradford-international-airport/'
  ],
  [
    'Cardiff Airport',
    'https://www.justpark.com/uk/parking/vale-of-glamorgan/cardiff-international-airport/'
  ],
  [
    'Glasgow Prestwick International Airport',
    'https://www.justpark.com/uk/parking/glasgow-airport/'
  ],
  [
    'Edinburgh Airport',
    'https://www.justpark.com/uk/parking/edinburgh-international-airport/'
  ],
  [
    'George Best Belfast City Airport',
    'https://www.justpark.com/uk/parking/belfast/george-best-belfast-city-airport/'
  ],
  [
    'Belfast International Airport',
    'https://www.justpark.com/uk/parking/antrim-and-newtownabbey/belfast-international-airport/'
  ]
];

const topTrain = [
  [
    'Birmingham New Street Station',
    'https://www.justpark.com/uk/parking/birmingham-new-street-station/'
  ],
  ['Brighton Station', 'https://www.justpark.com/uk/parking/brighton-station/'],
  ['Crewe Station', 'https://www.justpark.com/uk/parking/crewe-station/'],
  [
    'Doncaster Statoin',
    'https://www.justpark.com/uk/parking/doncaster-station/'
  ],
  [
    'Euston Station',
    'https://www.justpark.com/uk/parking/london/euston/euston-station/'
  ],
  [
    'Glasgow Central Station',
    'https://www.justpark.com/uk/parking/glasgow-central-station/'
  ],
  ["King's Cross Station", 'https://www.justpark.com/uk/parking/kings-cross/'],
  [
    'Manchester Piccadilly Station',
    'https://www.justpark.com/uk/parking/manchester-piccadilly-station/'
  ],
  [
    'Milton Keynes Central Station',
    'https://www.justpark.com/uk/parking/milton-keynes-central-station/'
  ],
  [
    'Newcastle Station',
    'https://www.justpark.com/uk/parking/newcastle-station/'
  ],
  [
    'Peterborough Station',
    'https://www.justpark.com/uk/parking/peterborough-station/'
  ],
  ['Reading Station', 'https://www.justpark.com/uk/parking/reading-station/'],
  ['Richmond Station', 'https://www.justpark.com/uk/parking/richmond-station/'],
  [
    'Southamption Central Station',
    'https://www.justpark.com/uk/parking/southampton-central-station/'
  ],
  [
    'St Pancras International Station',
    'https://www.justpark.com/uk/parking/london/st-pancras/st-pancras-international-station/'
  ],
  [
    'Stratford International',
    'https://www.justpark.com/uk/parking/london/maryland/stratford-station/'
  ],
  [
    'Watford Junction Station',
    'https://www.justpark.com/uk/parking/watford-junction-station/'
  ],
  ['York Station', 'https://www.justpark.com/uk/parking/york-station/']
];

const topSports = [
  ['Wembley Stadium', 'https://www.justpark.com/uk/parking/wembley-stadium/'],
  ['Twickenham Stadium', 'https://www.justpark.com/uk/parking/twickenham/'],
  ['Murrayfield Stadium', 'https://www.justpark.com/uk/parking/murrayfield/'],
  [
    'Prinipality Stadium',
    'https://www.justpark.com/uk/parking/principality-stadium/'
  ],
  ['Etihad Stadium', 'https://www.justpark.com/uk/parking/etihad-stadium/'],
  ['Anfield', 'https://www.justpark.com/uk/parking/anfield/'],
  ['Old Trafford', 'https://www.justpark.com/uk/parking/old-trafford/'],
  [
    'Tottenham Hotspur Stadium',
    'https://www.justpark.com/uk/parking/london/tottenham/tottenham-hotspur-stadium/'
  ],
  ['Emirates Stadium', 'https://www.justpark.com/uk/parking/emirates-stadium/'],
  ['St James Park', 'https://www.justpark.com/uk/parking/st-james-park/'],
  ['Stamford Bridge', 'https://www.justpark.com/uk/parking/stamford-bridge/'],
  ['Villa Park', 'https://www.justpark.com/uk/parking/villa-park/'],
  ['Goodison Park', 'https://www.justpark.com/uk/parking/goodison-park/'],
  ['Craven Cottage', 'https://www.justpark.com/uk/parking/craven-cottage/'],
  ['London Stadium', 'https://www.justpark.com/uk/parking/olympic-stadium/'],
  ['Selhurst Park', 'https://www.justpark.com/uk/parking/selhurst-park/'],
  ['Molineux Stadium', 'https://www.justpark.com/uk/parking/molineux/'],
  ['Stadium of Light', 'https://www.justpark.com/uk/parking/stadium-of-light/']
];

const tabContent = [
  topAttractions,
  topCities,
  topAirports,
  topTrain,
  topSports
];

const linkMapper = (onLinkClick) => ([name, url]: string[]) => (
  <div key={name}>
    <Link
      to={url}
      className={styles.link}
      onClick={() => {
        onLinkClick(url);
      }}
    >
      {name}
    </Link>
  </div>
);

const TopLocations = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const tabItems = useMemo(
    () => [
      t('homePage:topLocationsPopular', 'Popular Attractions'),
      t('homePage:topLocationsCities', 'Cities'),
      t('homePage:topLocationsAirports', 'Airports'),
      t('homePage:topLocationsTrain', 'Train Stations'),
      t('homePage:topLocationsSports', 'Sports Stadiums')
    ],
    [t]
  );

  const onLinkClick = useCallback((path: string) => {
    ampli.topLocationClicked({ path });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <BrandTypography level={3} bold>
          {t('homePage:topLocations', 'Top Locations')}
        </BrandTypography>
      </div>

      <div className={styles.tabsWrapper}>
        <Tabs active={tab} onChange={setTab} noWrap className={styles.tabs}>
          {tabItems.map((name) => (
            <TabItem key={name}>{name}</TabItem>
          ))}
        </Tabs>
      </div>

      {tabContent.map((content, index) => (
        <div
          className={index === tab ? styles.links : styles.hidden}
          aria-hidden={index !== tab}
        >
          {content.map(linkMapper(onLinkClick))}
        </div>
      ))}
    </div>
  );
};

export default TopLocations;
