import * as React from 'react';
import useLazyLoadIntersection from '../../hooks/useLazyLoadIntersection';

type Props = {
  children: React.Node;
  baseClassName?: string;
  wrapperModifier?: string;
  modifier?: string;
  innerRef?: (...args: Array<any>) => any;
  styles?: any;
};

const ContentModule = ({
  children,
  innerRef = () => {},
  baseClassName = 'hero',
  modifier = '',
  styles = {},
  wrapperModifier = ''
}: Props) => {
  const [ref, triggered] = useLazyLoadIntersection(100);

  return (
    <div
      className={`c-${baseClassName}-wrapper c-${baseClassName}-wrapper--${modifier} c-${baseClassName}-wrapper--${wrapperModifier} ${
        triggered ? `c-${baseClassName}--visible` : ''
      }`}
      ref={innerRef}
      style={styles}
    >
      <div
        ref={ref}
        className={`c-${baseClassName} c-${baseClassName}--${modifier}`}
      >
        {children}
      </div>
    </div>
  );
};

export default ContentModule;
