import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from '@not-remix-run/react';
import AppFeature from '../Common/AppFeature';
import ContentModule from '../Common/ContentModule';
import LegacyLink from '../Common/LegacyLink';
import type { T } from '../../types';
import ThreeFeatureSlider from '../Common/ThreeFeatureSlider';
import Media from '../Common/Media';
import pmeFind from '../../../static/images/find_parking.png';
import pmeReserve from '../../../static/images/reserve.png';
import pmePark from '../../../static/images/park_car.png';
import pmeFindWebp from '../../../static/images/find_parking.webp';
import pmeReserveWebp from '../../../static/images/reserve.webp';
import pmeParkWebp from '../../../static/images/park_car.webp';

import '../../../static/scss/components/hero.scss';
import '../../../static/scss/components/homePageBelowTheFold.scss';

import {
  selectIsAppFeatureLimited,
  selectPartnerName
} from '../../stores/partner';
import TopLocations from './TopLocations';

type ThreeFeatureSliderProps = {
  isAppFeatureLimited: boolean;
  t: T;
  partnerName: string;
  language: string;
};

const ThreeFeatureSliderPanel = ({
  isAppFeatureLimited,
  t,
  partnerName,
  language
}: ThreeFeatureSliderProps) => {
  const isWelsh = language === 'cy';

  return (
    <ThreeFeatureSlider
      title={t('homePage:threeFeatureTitle', 'Parking made easy')}
      modifier={{ 'parking-made-easy': true, partnerName }}
    >
      <Media
        modifier="stacked"
        title={t('homePage:threeFeatureSubTitleOne', 'Wherever, whenever')}
        image={pmeFind}
        width={360}
        height={357}
        nativeLazy
        webp={pmeFindWebp}
      >
        <ul>
          {!isAppFeatureLimited && !isWelsh && (
            <li>
              <span>
                <Trans i18nKey="homePage:chooseFromSpaces">
                  Choose from millions of spaces across the UK
                </Trans>
              </span>
            </li>
          )}

          <li>
            <span>
              {t(
                'homePage:threeFeatureFindBestOption',
                'Find your best option for every car journey'
              )}
            </span>
          </li>
        </ul>
      </Media>
      <Media
        modifier="stacked"
        title={t('homePage:threeFeatureSubTitleTwo', 'Peace of mind')}
        image={pmeReserve}
        width={240}
        height={228}
        nativeLazy
        webp={pmeReserveWebp}
      >
        <ul>
          <li>
            <span>
              {t(
                'homePage:threeFeatureViewInformation',
                'View information on availability, price and restrictions'
              )}
            </span>
          </li>
          {!isAppFeatureLimited && !isWelsh && (
            <li>
              <span>
                <Trans i18nKey="homePage:reserveInAdvance">
                  Reserve in advance at over 45,000+ locations
                </Trans>
              </span>
            </li>
          )}
        </ul>
      </Media>
      <Media
        modifier="stacked"
        title={t('homePage:threeFeatureSubTitleThree', 'Seamless experience')}
        image={pmePark}
        width={360}
        height={363}
        nativeLazy
        webp={pmeParkWebp}
      >
        <ul>
          {!isAppFeatureLimited && !isWelsh && (
            <li>
              <span>
                <Trans i18nKey="homePage:payWithApp">
                  Pay for JustPark spaces via the app or website
                </Trans>
              </span>
            </li>
          )}

          <li>
            <span>
              {t(
                'homePage:threeFeatureEasyDirections',
                'Follow easy directions and access instructions'
              )}
            </span>
          </li>
        </ul>
      </Media>
    </ThreeFeatureSlider>
  );
};
type Props = {
  showDefaultSectionSlider: boolean;
  showFeatureSections: boolean;
};

const HomePageBelowTheFold = ({
  showDefaultSectionSlider,
  showFeatureSections
}: Props) => {
  const isAppFeatureLimited = useSelector(selectIsAppFeatureLimited);
  const partnerName = useSelector(selectPartnerName);

  const {
    t,
    i18n: { language }
  } = useTranslation();

  return (
    <div>
      <div
        className={`c-hero__three_feature_slider  ${
          showDefaultSectionSlider
            ? 'c-hero__three_feature_slider--visible'
            : ''
        }`}
      >
        <ThreeFeatureSliderPanel
          isAppFeatureLimited={isAppFeatureLimited}
          t={t}
          partnerName={partnerName}
          language={language}
        />
      </div>

      {showFeatureSections && (
        <>
          <AppFeature />

          <ContentModule baseClassName="hero" modifier="rent-space">
            <div className="c-hero-content">
              <div className="c-hero-content__clear">
                <div className="c-hero-content__inner">
                  <Trans i18nKey="homePage:rentOutParkingSpace">
                    <h2>Rent out your parking or EV charging space</h2>
                    <p>
                      Make easy tax free money by renting out your parking or EV
                      charging space. It&lsquo;s free to list and only takes a
                      few minutes to get up and running.
                    </p>
                  </Trans>
                  <Link
                    className="primary-link"
                    to="/how-it-works/rent-out-your-driveway"
                  >
                    <Trans i18nKey="homePage:learnToEarn">
                      Learn how to earn today
                    </Trans>
                  </Link>
                </div>
              </div>
            </div>
          </ContentModule>

          <ContentModule baseClassName="hero" modifier="parking-management">
            <div className="c-hero-content">
              <div className="c-hero-content__clear">
                <div className="c-hero-content__inner">
                  <Trans i18nKey="homePage:carParkManagement">
                    <h2>Car park management</h2>
                    <p>
                      Maximise yield from underused car parks and vacant land,
                      or transform payments with the UK’s favourite parking app.
                    </p>
                    <LegacyLink
                      className="primary-link"
                      to="business/car-park-solutions/overview"
                    >
                      Learn about our solutions
                    </LegacyLink>
                  </Trans>
                </div>
              </div>
            </div>
          </ContentModule>
        </>
      )}

      <TopLocations />
    </div>
  );
};

export default HomePageBelowTheFold;
