// @ts-nocheck
import * as React from "react";
import { useCallback, useRef, useState } from "react";
import styles from "./sliderColumn.module.scss";
import PaginationDots from "../PaginationDots";
import useIsIntersectingWithRoot from "../../hooks/useIsIntersectingWithRoot";
import useMatchMediaMatches from "../../hooks/useMatchMediaMatches";

type Props = {
  children: React.Node;
};
type ItemProps = {
  index: number;
  onVisible: (a: number) => void;
  root: any;
  children: React.Node;
};
const SliderItem = ({ onVisible, index, root, children }: ItemProps) => {
  const ref = useRef();
  const onIntersecting = useCallback(() => {
    onVisible(index);
  }, [onVisible, index]);
  useIsIntersectingWithRoot(ref, root, 0.51, onIntersecting);
  return (
    <div className={styles.item} ref={ref}>
      {children}
    </div>
  );
};
const SliderColumn = ({ children }: Props) => {
  const ref = useRef();
  const [active, setActive] = useState(0);
  const childCount = children.length;
  const isGridLayout = useMatchMediaMatches("(min-width: 640px)") === true;
  const onVisible = useCallback(
    (index) => {
      if (isGridLayout) {
        return;
      }
      setActive(index);
    },
    [isGridLayout, setActive]
  );
  const scrollItemIntoView = (index) => {
    const width = ref.current.children[index].clientWidth;
    const offset = index * width;
    ref.current.scrollLeft = offset;
  };
  return (
    <div className={styles.container}>
      <div className={styles.sliderContainer} ref={ref}>
        {React.Children.map(children, (child, index) => (
          <SliderItem root={ref} index={index} onVisible={onVisible}>
            {child}
          </SliderItem>
        ))}
      </div>
      <div className={styles.paginationContainer}>
        <PaginationDots
          count={childCount}
          active={active}
          theme="brand"
          size="large"
          onClick={scrollItemIntoView}
        />
      </div>
    </div>
  );
};
export default SliderColumn;
