// @ts-nocheck
import React, { Children, cloneElement, Node, useRef } from "react";
import {
  RovingTabIndexProvider,
  useFocusEffect,
  useRovingTabIndex
} from "react-roving-tabindex";
import bem from "@justpark/helpers/src/bem/bem";
import Typography from "../Typography/index";
import "./tabs.scss";

type Props = {
  children?: Node;
  className?: string;
  cypress?: string | null;
  onChange?: (a: number) => void;
  active?: number;
  evenSpacing?: boolean;
  theme?: "minimal" | "dark" | "light-dashboard" | "minimal-black";
  brand?: boolean;
  noWrap?: boolean;
};
type TabItemProps = {
  active?: boolean;
  children: string;
  className?: string;
  cypress?: string | null;
  color?: "muted" | "primary-inverse";
  bold?: boolean;
  upperCase?: boolean;
  size?: "h3" | "primary" | "secondary" | "subtext" | "subhead" | "subtext2";
};
const baseClass = "c-tab";
export const TabItem = ({
  active = false,
  children,
  className = "",
  cypress = null,
  color = "muted",
  bold = false,
  upperCase = false,
  size = "h3"
}: TabItemProps) => (
  <Typography
    size={size}
    color={color}
    bold={bold}
    className={`${className} ${bem(baseClass, "tab-item", {
      active,
      "upper-case": upperCase
    })}`}
  >
    <span data-cy={cypress}>{children}</span>
  </Typography>
);

type TabProps = {
  active: boolean;
  children: React.Element<any>;
  // one child
  onClick: (event: SyntheticEvent) => any;
};
const Tab = ({ active, children, onClick }: TabProps) => {
  const ref = useRef(null);
  const [tabIndex, focused, rtiKeyDown, rtiClick] = useRovingTabIndex(ref);
  useFocusEffect(focused, ref);
  const onKeydown = (event: SyntheticEvent) =>
    event.keyCode === 13 ? onClick() : null;
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        rtiClick(e);
      }}
      onKeyDown={(e) => {
        e.preventDefault();
        onKeydown(e);
        rtiKeyDown(e);
      }}
      className={bem(baseClass, "item", {
        active
      })}
      ref={ref}
      tabIndex={tabIndex}
      role="button"
    >
      {Children.only(children).type === TabItem
        ? cloneElement(children, {
            active
          })
        : children}
    </div>
  );
};
const Tabs = ({
  children = null,
  className = "",
  onChange = () => {},
  active = 0,
  cypress = null,
  evenSpacing = false,
  theme,
  noWrap = false,
  brand = false
}: Props) => {
  return (
    <div
      className={`${bem(baseClass, null, {
        "even-spacing": evenSpacing,
        theme: `theme-${theme}`,
        brand,
        noWrap
      })} ${className}`}
      data-cy={cypress}
    >
      <RovingTabIndexProvider>
        {Children.map(children, (element, index) => {
          return element ? (
            <Tab
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              active={active === index}
              onClick={() => onChange(index)}
              theme={theme}
            >
              {element}
            </Tab>
          ) : null;
        })}
      </RovingTabIndexProvider>
    </div>
  );
};

export default Tabs;
