import React from 'react';
import bem from '@justpark/helpers/src/bem/bem';
import { Trans } from 'react-i18next';
import ContentModule from './ContentModule';
import AppIcons from './AppIcons';

import '../../../static/scss/components/downloadApp.scss';

const title = "UK's favourite";

const baseClass = 'c-downloadApp';
type Props = { name?: string };

const AppFeature = ({ name = 'London' }: Props) => (
  <div
    className={`${baseClass}__app-feature ${baseClass}__app-feature--visible`}
  >
    <ContentModule baseClassName="downloadApp">
      <div className={bem(baseClass, 'left')}>
        <h2>
          <Trans i18nKey="appFeature:title">
            Download the <span>{{ title }}</span> parking app
          </Trans>
        </h2>
        <p>
          <Trans i18nKey="appFeature:description">
            Rated 5 stars with an average satisfaction rating of 96%, JustPark
            is the UK’s favourite parking service. But don’t just take our word
            for it – check out some of the latest customer reviews below for our{' '}
            {{ name }} parking spaces.
          </Trans>
        </p>
        <AppIcons
          baseClassName={bem(baseClass, 'appIcons')}
          locationTrackEvent="home_body"
        />
      </div>
    </ContentModule>
  </div>
);

export default AppFeature;
