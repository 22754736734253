import React from 'react';
import Markdown from 'markdown-to-jsx';
import { Link } from '@not-remix-run/react';
import { notify } from '../helpers/bugsnag/BugsnagClient';

type Props = {
  content: string;
  type: string;
  options?: any;
};
type CmsLinkProps = {
  href: string;
  children: string;
};

const CmsLink = ({ href, children }: CmsLinkProps) => {
  const text = children;
  const url = new URL(href);

  const isJustPark =
    url.host === 'www.justpark.com' || url.host.includes('justpark.me');
  // Basic support for handling select React Router links
  const isReactRouter =
    url.pathname === '/' || url.pathname.startsWith('/uk/parking/');

  if (isJustPark && isReactRouter) {
    return <Link to={url.pathname}>{text}</Link>;
  }

  return <a href={href}>{text}</a>;
};

const CmsContent = ({ content, type, options = {} }: Props) => {
  if (!content) {
    notify(`${type} cms content is empty`);
  }

  return (
    <Markdown
      options={{
        ...options,
        overrides: {
          a: {
            component: CmsLink
          }
        }
      }}
    >
      {content}
    </Markdown>
  );
};

export default CmsContent;
