import React from 'react';
import bem from '@justpark/helpers/src/bem/bem';
import SliderColumn from '@justpark/ui/src/components/SliderColumn';
import Heading from '@justpark/ui/src/components/Heading';

import '../../../static/scss/components/threeFeature.scss';

export type Props = {
  modifier?: any;
  children: Array<any>;
  title: string;
};

const baseClass = 'c-three-feature-slider';

const ThreeFeatureSlider = ({
  children,
  modifier = 'parking-made-easy',
  title = 'Parking made easy'
}: Props) => (
  <div className={bem(baseClass, null, modifier)}>
    <div className={bem(baseClass, 'heading-container')}>
      <Heading level={2} color="brand">
        {title}
      </Heading>
    </div>

    <div className={bem(baseClass, 'slider-container')}>
      <SliderColumn>{children}</SliderColumn>
    </div>
  </div>
);

export default ThreeFeatureSlider;
