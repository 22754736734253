import { useEffect } from 'react';
import Analytics from '../helpers/Tracking/Analytics';

const analytics = new Analytics();

const usePageView = (pageName: string) => {
  useEffect(() => {
    analytics.page(pageName);
  }, [pageName]);
};

export default usePageView;
