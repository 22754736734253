import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import HomePageBelowTheFold from '../components/Home/HomePageBelowTheFold';
import usePageView from '../hooks/usePageview';
import { selectIsAppFeatureLimited } from '../stores/partner';
import SearchHero from '../components/SearchHero';
import { ampli } from '../helpers/Tracking/amplitude';
import getQueryParamSearchType from '../helpers/getQueryParamSearchType';
import TrustPilotReviews from '../components/Global/TrustPilotReviews';

import styles from '../../static/scss/pages/home.module.scss';

const cypress = 'home';

const reviews = [
  {
    comment:
      'Easy to follow instructions. Plenty of parking spaces and very secure Would highly recommend.',
    createdAt: '2023-10-14T20:00:38+01:00',
    id: '2056965',
    rating: 5,
    sender: 'Caroline S',
    location: {
      title: 'Q Park Mayfair Car Park',
      slug: '/uk/parking/london/s/old-burlington-street-london-w1s3af/'
    }
  },
  {
    comment:
      'hotel easy to spot in plenty of time. bus lane not a problem. followed directions and instructions. so parked car in space. went to hotel reception and showed booking ref. filled out card with reg and return date. they gave me a ticket to display in window of car. and then were very informative about the public transport links to Heathrow. so following their advice walked 200m max down road to bus stop and caught the X140 bus straight to Heathrow Central bus station.',
    createdAt: '2023-04-10T11:52:27+01:00',
    id: '2006886',
    rating: 5,
    sender: 'Ian J',
    location: {
      title: 'London Heathrow Marriott Hotel Car Park',
      slug: '/uk/parking/hayes/s/bath-road-hayes-ub3-p559b97d577baa/'
    }
  },
  {
    comment:
      'Fantastic location for parking up and watching the Arsenal play at the Emirates. Will definitely book again. Great communication from the host also.',
    createdAt: '2023-03-23T09:05:01+00:00',
    id: '1990351',
    rating: 5,
    sender: 'S H',
    location: {
      title: 'Parking space on Courtney Road, N7',
      slug: '/uk/parking/london/s/courtney-road-london-n7/'
    }
  },
  {
    comment:
      'Safe and secure spot in a quiet, residential area. I have a large SUV and the space was adequate. Lovely message from space owner afterwards, too! Would use again\n',
    createdAt: '2023-03-21T15:59:20+00:00',
    id: '1989131',
    rating: 5,
    sender: 'Sharon C',
    location: {
      title: 'Parking on Woodrush Close, SE14',
      slug: '/uk/parking/london/s/woodrush-close-london-se146dj/'
    }
  },
  {
    comment: 'Simply booked. Great location. Securely Parked. Will use again.',
    createdAt: '2023-03-17T09:27:50+00:00',
    id: '1984466',
    rating: 5,
    sender: 'Stuart R',
    location: {
      title: 'Marlin Canary Wharf Car Park',
      slug: '/uk/parking/london/s/byng-street-london-e14/'
    }
  },
  {
    comment: 'Easy as it gets. EV charging was effortless too.',
    createdAt: '2023-02-22T11:11:40+00:00',
    id: '1963976',
    rating: 5,
    sender: 'Liam M',
    location: {
      title: 'Parking on Blondin Street, E3',
      slug: '/uk/parking/london/s/blondin-street-london-e3-2tr-p5c756efe0817a/'
    }
  },
  {
    comment:
      'No problems with parking large safe space easy to find 2 minutes from Twickenham South Stand',
    createdAt: '2023-02-11T19:37:19+00:00',
    id: '1953720',
    rating: 5,
    sender: 'Shawn A',
    location: {
      title: 'Parking on Varsity Drive, TW1',
      slug:
        '/uk/parking/twickenham/s/varsity-drive-twickenham-tw1-p54d3e484b2f46/'
    }
  },
  {
    comment:
      'Great spot, really tucked away yet close enough to the ground! Will definitely use again! Thanks!',
    createdAt: '2023-01-29T13:37:04+00:00',
    id: '1941976',
    rating: 5,
    sender: 'Matt W',
    location: {
      title: 'Parking on St. Stephens Road, B71',
      slug:
        '/uk/parking/west-bromwich/s/st-stephens-road-west-bromwich-b71-4lr/'
    }
  },
  {
    comment:
      'First time I used or knew about JustParking. Excellent, right next to our hotel, cheaper and more convenient than the alternatives. Will definitely use again.',
    createdAt: '2023-01-22T20:37:26+00:00',
    id: '1936654',
    rating: 5,
    sender: 'John H',
    location: {
      title: 'Sussex Gardens Car Park',
      slug: '/uk/parking/london/s/sussex-gardens-london-w2-1tp/'
    }
  },
  {
    comment:
      'Easy and user friendly experience. After, I had to change plans in my day schedule, I had to extend my booking time which was easy to do without any stress.\n',
    createdAt: '2022-11-13T15:59:09+00:00',
    id: '1871460',
    rating: 5,
    sender: 'George O',
    location: {
      title: "Sainsbury's Nine Elms, London",
      slug: '/uk/parking/london/s/62-wandsworth-road-london-sw8-2lf/'
    }
  }
];

const Home = () => {
  const isAppFeatureLimited = useSelector(selectIsAppFeatureLimited);

  const {
    t,
    i18n: { language }
  } = useTranslation();

  const isWelsh = language === 'cy';

  const showDefaultSectionSlider = true;
  const showFeatureSections = !isAppFeatureLimited && !isWelsh;

  usePageView('homepage');
  useEffect(() => {
    const searchType = getQueryParamSearchType('monthly');
    ampli.homepageShown({ search_type: searchType });
  }, []);

  const title = (
    <Trans i18nKey="homePage:searchModuleTitle">
      <span className={styles.brandColor}>The smarter way</span> to find parking
    </Trans>
  );

  const body = t(
    'homePage:searchModuleBody',
    'Thousands of reservable spaces located right where you need them. Join over 13 million drivers and enjoy stress-free, cheaper parking.'
  );

  const locationLabel = t(
    'homePage:searchModuleLocationLabel',
    'Where would you like to park?'
  );
  const locationPlaceholder = t(
    'homePage:searchModuleLocationPlaceholder',
    'Enter a place or postcode'
  );
  const submitText = t(
    'homePage:searchModuleSubmitText',
    'Show parking spaces'
  );

  return (
    <div data-cy={cypress}>
      <Helmet
        title={t(
          'homePage:title',
          'JustPark - The Parking App | Find parking in seconds'
        )}
        titleTemplate="%s"
      >
        <link rel="canonical" href="https://www.justpark.com/" />
      </Helmet>

      <SearchHero
        timezone="Europe/London"
        heroImageUrl="home/hero-2.jpg"
        title={title}
        body={body}
        centerPoint={undefined}
        locationLabel={locationLabel}
        locationPlaceholder={locationPlaceholder}
        submitText={submitText}
        showDriveupOnlyForm
        showFamiliarSpaces
        showAirports
      />

      <div className={styles.reviews}>
        <TrustPilotReviews reviews={reviews} />
      </div>
      <HomePageBelowTheFold
        showDefaultSectionSlider={showDefaultSectionSlider}
        showFeatureSections={showFeatureSections}
      />
    </div>
  );
};

export default Home;
