/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import '../../../static/scss/components/media.scss';

export type Props = {
  modifier?: string;
  children?: any;
  image?: string;
  title?: any;
  webp: string;
  width?: null | number;
  height?: null | number;
};

const Media = ({
  modifier = null,
  children = false,
  image = false,
  title = false,
  webp,
  width = null,
  height = null
}: Props) => {
  const baseClassName = 'c-media';
  let className = baseClassName;

  if (modifier) {
    if (modifier.constructor === Array) {
      className = modifier.map(
        (mod) => `${className} ${baseClassName}--${mod}`
      );
    } else {
      className += ` ${baseClassName}--${modifier}`;
    }
  }

  let body;

  if (typeof children === 'string') {
    body = <p className="c-media-body">{children}</p>;
  } else if (React.isValidElement(children)) {
    const child = React.Children.only(children);
    body = React.cloneElement(child, {
      ...child.props,
      className: 'c-media-body'
    });
  }

  return (
    <div className={className}>
      <picture>
        {webp && <source srcSet={webp} type="image/webp" />}
        <source srcSet={image} type="image/png" />
        <img
          className={`${baseClassName}-figure`}
          src={image}
          alt={title}
          width={width}
          height={height}
          loading="lazy"
        />
      </picture>

      {title && <h3 className={`${baseClassName}-title`}>{title}</h3>}
      {body}
    </div>
  );
};

export default Media;
